"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractSectionVariants = exports.extractTlSections = exports.extractTlBlocks = exports.extractBlocks = void 0;
var lodash_1 = require("lodash");
var BlockModel_1 = require("../components/TemplateRender/Blocks/BlockModel");
var sort_1 = require("./sort");
// [DC-4621] [DC-5075]
function extractBlocks(elementsList, isContentEditable) {
    if (isContentEditable === void 0) { isContentEditable = true; }
    var blocks = [];
    var templateElements = elementsList.templateElements;
    // НЕ блок в ББ (fakeElement_id) и НЕ вариант секции (есть parentElement_id)
    var blocksList = templateElements === null || templateElements === void 0 ? void 0 : templateElements.filter(function (x) { return !x.fakeTemplate_id && !x.parentElement_id; }).sort((0, sort_1.sortByNumberField)('templateElementNumber'));
    // Блоки (не варианты секции), относящиеся к ББ
    var fakeBlocksList = templateElements === null || templateElements === void 0 ? void 0 : templateElements.filter(function (x) { return x.fakeTemplate_id && !x.parentElement_id; });
    var fakeBlocksListByNumber = (0, lodash_1.groupBy)(fakeBlocksList, 'fakeTemplate_id.templateElementNumber');
    Object.keys(fakeBlocksListByNumber).forEach(function (templateElementNumber) {
        var group = fakeBlocksListByNumber[templateElementNumber].sort((0, sort_1.sortByNumberField)('templateElementNumber'));
        blocksList.splice.apply(blocksList, __spreadArray([Number(templateElementNumber), 0], group, false));
    });
    blocksList === null || blocksList === void 0 ? void 0 : blocksList.forEach(function (curBlock) {
        var blockModel = (0, BlockModel_1.getBlockModel)(curBlock);
        // настройка перезаписывается, если редактирование запрещено настройками шаблона
        if (!isContentEditable) {
            blockModel.isContentEditable = false;
        }
        blocks.push((0, BlockModel_1.getBlockModel)(blockModel));
    });
    blocks.sort(sort_1.sortByOrderField);
    return blocks;
}
exports.extractBlocks = extractBlocks;
function extractTlBlocks(templatedListElements) {
    var blocks = [];
    templatedListElements === null || templatedListElements === void 0 ? void 0 : templatedListElements.filter(function (x) { return !x.templateElement.fakeTemplate_id && !x.templateElement.parentElement_id; }).sort((0, sort_1.sortByNumberField)('templateElementNumber')).forEach(function (_a, idx) {
        var placeholderId = _a.placeholderId, templateElement = _a.templateElement;
        templateElement.templateElementNumber = idx;
        var blockModel = (0, BlockModel_1.getBlockModel)(templateElement);
        blockModel.isContentEditable = false;
        if (blockModel.type === 'section') {
            blockModel.sectionVariant = templateElement.subType;
        }
        // вернуть addTL если будет проблема и баги
        blockModel.subType = 'templatedListBlock'; // TODO: почему addTL, а сразу не присваивается templatedListBlock?
        blockModel.templateListId = templateElement.template_id.id;
        blocks.push({
            placeholderId: placeholderId,
            templateElement: blockModel,
        });
    });
    return blocks;
}
exports.extractTlBlocks = extractTlBlocks;
function extractTlSections(extractsRepeatedSections) {
    var blocks = [];
    extractsRepeatedSections === null || extractsRepeatedSections === void 0 ? void 0 : extractsRepeatedSections.forEach(function (_a) {
        var templateElement = _a.templateElement;
        if (!templateElement.parentElement_id) {
            return;
        }
        var blockModel = (0, BlockModel_1.getBlockModel)(templateElement);
        blockModel.subType = 'templatedListBlock';
        blockModel.isContentEditable = false;
        blocks.push(blockModel);
    });
    return blocks;
}
exports.extractTlSections = extractTlSections;
function extractSectionVariants(elementsList, isContentEditable) {
    var _a;
    if (isContentEditable === void 0) { isContentEditable = false; }
    var blocks = [];
    (_a = elementsList === null || elementsList === void 0 ? void 0 : elementsList.templateElements) === null || _a === void 0 ? void 0 : _a.forEach(function (block) {
        if (!block.parentElement_id) {
            return;
        }
        var blockModel = (0, BlockModel_1.getBlockModel)(block);
        blockModel.isContentEditable = isContentEditable && !blockModel.emptyValue;
        blocks.push(blockModel);
    });
    return blocks;
}
exports.extractSectionVariants = extractSectionVariants;
